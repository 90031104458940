import ContributionButton from "../components/ContributionButton";
import { getConfig } from "../utils/helpers";
import ContributionOtherButton from "./ContributionOtherButton";

function ContributionAmountSelector() {
  const instanceConfig = getConfig();
  const amounts = instanceConfig.amounts;

  return (
    <article className="contributionAmounts">
      <h2>Make a One-Time Contribution</h2>
      <div className="contributionAmountsList cols-2">
        {amounts.map((amount, index) => (
          <ContributionButton amount={amount} key={index} />
        ))}
        <ContributionOtherButton />
      </div>
    </article>
  );
}

export default ContributionAmountSelector;
