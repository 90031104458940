import styled from "styled-components";
import { getConfig } from "../utils/helpers";
import { Link } from "react-router-dom";

const instanceConfig = getConfig();

const StyledSubmit = styled.button.attrs((props) => ({
  type: "submit",
  className: "submit-btn",
}))`
  background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
  cursor: pointer;
  &:disabled {
    background: transparent;
    color: #777;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button.attrs((props) => ({
  className: "submit-btn",
}))`
  background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
  cursor: pointer;
  &:disabled {
    background: transparent;
    color: #777;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputRow2 = styled.div`
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
`;

const InputRow3 = styled.div`
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.25rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
`;

const StyledCancelButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  background-color: transparent;
  padding: 20px;
  color: #000;
  border: 2px solid #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledErrorResponse = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  &:empty {
    display: none;
  }
`;

const StyledContributionButton = styled(Link).attrs((props) => ({
  className: "contribution-btn contribution-other-btn",
}))`
  background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
  cursor: pointer;
  &.disabled {
    background: gray;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const OtherBox = styled.div`
  grid-column: 1 / span 2;
  gap: 15px;
  display: grid;
  align-items: start;
  justify-content: center;
  align-items: center;

  .OtherHeading {
    display: grid;
    gap: 20px;
    text-align: center;
  }
`;

const OtherInput = styled.input.attrs((props) => ({
  placeholder: "0.00",
  type: "number",
  step: "any",
}))`
  border-width: 0 0 2px;
  font-size: 30px;
  color: #555;
  font-weight: bold;
  padding: 10px 0 10px 30px;
  border-radius: 0;
  margin-bottom: 40px;
  width: 150px;

  &:focus-visible,
  &:focus {
    outline: none;
    border-width: 0 0 2px;
    border-color: mediumseagreen;
  }
`;

const OtherInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;

  &:before {
    content: ${(props) => (!props.isPercent ? '"$"' : '""')};
    font-size: 30px;
    color: mediumseagreen;
    margin-right: -15px;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: ${(props) => (props.isPercent ? '"%"' : '""')};
    font-size: 30px;
    color: mediumseagreen;
    margin-left: -25px;
    position: relative;
    z-index: 1;
  }
`;

export {
  StyledButton,
  StyledSubmit,
  StyledForm,
  InputRow2,
  InputRow3,
  StyledLabel,
  StyledInput,
  StyledCancelButton,
  StyledErrorResponse,
  StyledContributionButton,
  OtherBox,
  OtherInput,
  OtherInputContainer,
};
