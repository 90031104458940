import { useContext, useState, useEffect, useRef } from "react";
import { IntentContext } from "../utils/IntentProvider";
import { Link, useNavigate } from "react-router-dom";
import { StyledButton, StyledSubmit } from "../utils/styled";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getConfig } from "../utils/helpers";

function Lookup() {
  sessionStorage.removeItem("user");

  const { resetIntent } = useContext(IntentContext);
  const [formType, setFormType] = useState("");
  const [lookupFields, setLookupFields] = useState("");
  const navigate = useNavigate();
  const emailRef = useRef();
  const nameRef = useRef();
  const idRef = useRef();
  const phoneRef = useRef();

  const resetLookup = (e) => {
    e.preventDefault();
    setFormType("");
    setLookupFields("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (idRef.current) sessionStorage.setItem("memberID", idRef.current.value);
    if (nameRef.current)
      sessionStorage.setItem("last_name", nameRef.current.value);
    if (emailRef.current)
      sessionStorage.setItem("emailAddress", emailRef.current.value);
    if (phoneRef.current)
      sessionStorage.setItem(
        "phone",
        phoneRef.current.state.formattedNumber.replace(
          /^\+?1?\s*?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/,
          "$1$2$3"
        )
      );

    navigate("/details", { replace: true });
  };

  useEffect(() => {
    resetIntent({ id: 0, amount: 0 });
  }, [resetIntent]);

  useEffect(() => {
    const selectFormType = (
      <>
        <h1>Lookup Stakeholder</h1>
        <p>Choose how you want to lookup the individual's information</p>
        <div className="cols-2 mt-small">
          <StyledButton type="button" onClick={() => setFormType("memberID")}>
            Member ID
          </StyledButton>
          <StyledButton
            type="button"
            onClick={() => setFormType("emailAddress")}
          >
            Email Address
          </StyledButton>
          {getConfig().enablePhoneLookup ? (
            <StyledButton type="button" onClick={() => setFormType("phone")}>
              Phone
            </StyledButton>
          ) : (
            ""
          )}
          {getConfig().enableUserCreate ? (
            <StyledButton
              type="button"
              onClick={() => navigate("/create-member")}
            >
              Create Member
            </StyledButton>
          ) : (
            ""
          )}
        </div>
      </>
    );

    const lookupByName = (
      <>
        <h1>Lookup Stakeholder by Email</h1>
        <p>
          Use this form to look up individuals by their Email Address and Last
          Name. If found, the system will load their information and provide a
          form for you to capture a new one-time payment.
        </p>
        <label htmlFor="last_name">Last Name:</label>
        <input type="text" name="last_name" ref={nameRef} required />
        <label htmlFor="emailAddress">Email Address:</label>
        <input type="text" name="emailAddress" ref={emailRef} required />
      </>
    );

    const lookupByExternalID = (
      <>
        <h1>Lookup Stakeholder by ID</h1>
        <p>
          Use this form to look up individuals by their member ID value. If
          found, the system will load their information and provide a form for
          you to capture a new one-time payment.
        </p>
        <label htmlFor="memberID">Member ID:</label>
        <input type="text" name="memberID" ref={idRef} required />
      </>
    );

    const lookupByPhone = (
      <>
        <h1>Lookup Stakeholder by Phone Number</h1>
        <p>
          Use this form to look up individuals by their Phone Number. If found,
          the system will load their information and provide a form for you to
          capture a new one-time payment.
        </p>
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          name="phone"
          country={"us"}
          onlyCountries={["us"]}
          ref={phoneRef}
          countryCodeEditable={false}
          autoFormat
          disableDropdown
          copyNumbersOnly
        />
      </>
    );

    switch (formType) {
      case "memberID":
        setLookupFields(lookupByExternalID);
        break;
      case "emailAddress":
        setLookupFields(lookupByName);
        break;
      case "phone":
        setLookupFields(lookupByPhone);
        break;
      default:
        setLookupFields(selectFormType);
    }
  }, [formType, navigate]);

  return (
    <>
      <main>
        <form className="lookup" onSubmit={handleSubmit}>
          {lookupFields}
          {formType ? (
            <StyledSubmit className="submit-btn">
              Lookup Stakeholder
            </StyledSubmit>
          ) : (
            ""
          )}
        </form>
      </main>
      {formType ? (
        <Link
          to="/"
          onClick={(e) => resetLookup(e)}
          className="start-over-link"
        >
          Start Over
        </Link>
      ) : (
        ""
      )}
    </>
  );
}

export default Lookup;
