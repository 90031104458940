import { useContext } from "react";
import { IntentContext } from "../utils/IntentProvider";
import { PayrollContext } from "../utils/PayrollProvider";
import { getConfig, moneyFormat, percentFormat } from "../utils/helpers";

function MemberIntentProfile(props) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { intent } = useContext(IntentContext);
  const { payroll } = useContext(PayrollContext);
  const type = getConfig()?.payroll?.type || "dollar";

  return (
    <dl>
      <dt>Individual:</dt>
      <dd>
        {user.name}
        <br />#{user.memberID}
      </dd>

      {intent?.amount ? (
        <>
          <dt>Amount:</dt>
          <dd>${intent.amount}</dd>
        </>
      ) : null}

      {intent?.action_taken_id ? (
        <>
          <dt>Transaction ID:</dt>
          <dd>{intent.action_taken_id}</dd>
        </>
      ) : null}

      {payroll?.amount ? (
        <>
          <dt>Payroll Deduction:</dt>
          <dd>
            {type === "percentage"
              ? percentFormat(payroll.amount)
              : moneyFormat(payroll.amount)}{" "}
            per pay period
          </dd>
        </>
      ) : null}
    </dl>
  );
}

export default MemberIntentProfile;
