import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser, setStoredUser } from "../api/user";
import RecentContributions from "../components/RecentContributions";
import ContributionTotals from "../components/ContributionTotals";
import MemberDetails from "../components/MemberDetails";
import ContributionAmountSelector from "../components/ContributionAmountSelector";
import PayrollDeductionSelector from "../components/PayrollDeductionSelector";
import { getConfig } from "../utils/helpers";
import { StyledButton } from "../utils/styled";
import { useNavigate } from "react-router-dom";

function GenerateTransaction() {
  const instanceConfig = getConfig();
  const payrollEnabled = instanceConfig?.payroll?.enabled;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [contributionType, setContributionType] = useState(
    payrollEnabled ? "" : "one-time"
  );

  const getTheUser = async () => {
    setLoading(true);
    setUser(await getUser());
    setLoading(false);
  };

  const setCurrentUser = (userID) => {
    const currentUser =
      user.filter((person) => person.id === userID)[0] ?? false;
    setStoredUser(currentUser ?? "");
    setUser(currentUser);
  };

  useEffect(() => {
    getTheUser();
    return () => {};
  }, [setUser, setLoading]);

  let content = "loading user...";

  const navigate = useNavigate();

  if (!user?.id && !Array.isArray(user) && !loading) {
    content = (
      <article>
        <h1>Make a Contribution</h1>
        <p>{user?.message || "User Not Found"}</p>
      </article>
    );
  }

  if (Array.isArray(user)) {
    const personContent = user.map((person, key) => (
      <li key={key}>
        <input
          type="radio"
          name="multiple-user-select"
          id={"multiple-user-select-" + key}
          value={person.id}
          onClick={() => setSelectedUser(person.id)}
        />
        <label htmlFor={"multiple-user-select-" + key}>
          <MemberDetails user={person} hideTitle={true} />
        </label>
      </li>
    ));
    content = (
      <article>
        <h1>Multiple Members Found</h1>
        <ul id="multiple-member-list">{personContent}</ul>

        <StyledButton
          type="button"
          onClick={() => setCurrentUser(selectedUser)}
          className={!selectedUser ? "disabled" : ""}
        >
          Proceed to Contribution Screen
        </StyledButton>
      </article>
    );
  }

  const startContent = (
    <>
      <h1>Make a Contribution</h1>
      <div
        className="cols-2"
        style={{
          borderBottom: "1px solid #ccc",
          paddingBottom: "30px",
          marginBottom: "15px",
        }}
      >
        <div>
          <MemberDetails user={user} />

          <Link
            to="/update-profile"
            style={{ marginTop: "1rem", textAlign: "left" }}
            className="start-over-link"
          >
            Update Profile
          </Link>
        </div>
        <div className="contrib-stats-right">
          <RecentContributions contributions={user?.contributions?.recent} />
        </div>
      </div>
      <ContributionTotals user={user} />
      <hr />
    </>
  );

  if (user.id && payrollEnabled && !contributionType) {
    content = (
      <article>
        {startContent}
        <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
          Select Contribution Type
        </h2>
        <div className="contribution-type-selector cols-2">
          <StyledButton
            type="button"
            onClick={() => setContributionType("one-time")}
          >
            One-time Contribution
          </StyledButton>
          <StyledButton
            type="button"
            onClick={() => setContributionType("payroll")}
          >
            Payroll Deduction
          </StyledButton>
        </div>
      </article>
    );
  }

  const goBackLink = payrollEnabled ? (
    <Link
      onClick={() => setContributionType("")}
      className="start-over-link"
      style={{ marginTop: "1rem" }}
    >
      Go Back
    </Link>
  ) : (
    ""
  );

  if (user.id && contributionType) {
    content = (
      <>
        <article>
          {startContent}

          {instanceConfig.cap && user.cap <= 0 ? (
            <p>{instanceConfig.capMsg}</p>
          ) : contributionType ? (
            contributionType === "one-time" ? (
              <ContributionAmountSelector />
            ) : (
              <PayrollDeductionSelector />
            )
          ) : (
            ""
          )}

          {goBackLink}
        </article>
      </>
    );
  }

  return (
    <>
      <main className="member-page">{content}</main>
      <Link to="/" className="start-over-link">
        Start Over
      </Link>
    </>
  );
}

export default GenerateTransaction;
