import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayrollContext } from "../utils/PayrollProvider";
import { getUserFromStorage } from "../api/user";
import { setPaymentDeduction } from "../api/payroll";
import MemberIntentProfile from "../components/MemberIntentProfile";
import ErrorComponent from "../components/ErrorComponent";
import { StyledButton } from "../utils/styled";
import { Link } from "react-router-dom";

function ConfirmPayroll() {
  const navigate = useNavigate();
  const user = getUserFromStorage();
  const { payroll } = useContext(PayrollContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await setPaymentDeduction(payroll);
      if (response.success) {
        setSuccess(true);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError("Failed to set payroll deduction");
    }
    setLoading(false);
  };

  if (!user || !payroll) return <ErrorComponent />;

  return (
    <>
      <main className="transaction-page">
        {success ? (
          <>
            <MemberIntentProfile />
            <article className="success-section">
              <h2>Success!</h2>
              <p>
                Thank you {user.first_name}! Your payroll deduction has been
                set.
              </p>
              <Link to="/" className="begin-new-link">
                Begin a New Transaction
              </Link>
            </article>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <h2>Confirm Payroll Deduction</h2>
            <p>Please confirm your payroll deduction selection:</p>
            <MemberIntentProfile />
            <div className="confirmation-section">
              {error && <div className="error-message">{error}</div>}
              <div className="button-group">
                <StyledButton onClick={handleConfirm} disabled={loading}>
                  {loading ? "Processing..." : "Confirm Deduction"}
                </StyledButton>
              </div>
            </div>
            <p
              className="disclaimer-text"
              style={{
                textAlign: "center",
                fontSize: "0.8rem",
                width: "80%",
                lineHeight: "1.3rem",
                color: "#333",
              }}
            >
              I authorize the PAC to deduct the designated amount per pay period
              from my paycheck. It is my understanding that this authorization
              will remain in effect until I revoke that authorization in
              writing.
            </p>
            <p
              className="disclaimer-text"
              style={{
                textAlign: "left",
                fontSize: "0.8rem",
                width: "80%",
                lineHeight: "1.3rem",
                color: "#333",
                marginTop: "1rem",
              }}
            >
              I am contributing to the PAC as indicated below. Prior to
              contributing, I am aware:
              <br />
              <ul>
                <li>
                    Contributions to the PAC will be used in connection with federal elections
                    and are subject to the prohibitions and limitations of the Federal Election
                    Campaign Act.
                </li>
                <li>
                    Of my right to refuse to contribute without reprisal.
                </li>
                <li>
                    The guidelines for contributing are merely suggestions. I may contribute
                    more or less than the guidelines suggest or nothing at all, and I will not
                    be favored or disadvantaged by reason of the amount of my contribution or my
                    decision not to contribute.
                </li>
                <li>
                    Contributions to the PAC are not deductible for federal income tax purposes.
                </li>
                <li>
                    I must be a U.S. citizen or Permanent Resident Alien (i.e., a Green Card
                    holder residing in the U.S.) to make, or be solicited for, a contribution.
                </li>
              </ul>   
            </p>
          </div>
        )}
      </main>
      {!success && (
        <Link
          onClick={() => navigate(-1)}
          className="start-over-link"
          style={{ placeSelf: "center" }}
        >
          Go back
        </Link>
      )}
    </>
  );
}

export default ConfirmPayroll;
