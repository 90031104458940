import { moneyFormat, percentFormat } from "../utils/helpers";
import { getConfig } from "../utils/helpers";
import { StyledContributionButton } from "../utils/styled";
import { useContext } from "react";
import { PayrollContext } from "../utils/PayrollProvider";
import { getUserFromStorage } from "../api/user";
import { useNavigate } from "react-router-dom";

function PayrollButton(props) {
  const instanceConfig = getConfig();
  const type = instanceConfig?.payroll?.type || "dollar";
  const { setPayroll } = useContext(PayrollContext);
  const user = getUserFromStorage();
  const navigate = useNavigate();

  const handlePayrollDeduction = (e) => {
    e.preventDefault();
    setPayroll({
      id: parseInt(user.id || 0),
      amount: parseFloat(props.amount || 0),
    });
    navigate("/payroll");
  };

  return (
    <StyledContributionButton onClick={handlePayrollDeduction}>
      {type === "percentage"
        ? percentFormat(props.amount)
        : moneyFormat(props.amount)}
    </StyledContributionButton>
  );
}

export default PayrollButton;
