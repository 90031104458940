import { getConfig } from "../utils/helpers";

export const setPaymentDeduction = async (payrollData) => {
  const instanceConfig = getConfig();
  const url = `${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/set-payroll/${instanceConfig.action}/${instanceConfig.instanceID}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: getConfig().auth,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payrollData),
    });

    return await response.json();
  } catch (error) {
    return { success: false, message: error.message };
  }
};
