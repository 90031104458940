import { moneyFormat, percentFormat, getConfig } from "../utils/helpers";

function ContributionTotals(props) {
  const instanceConfig = getConfig();
  const type = instanceConfig?.payroll?.type || "dollar";
  const user = props.user;
  const hasPayroll = instanceConfig?.payroll?.enabled && user?.payroll?.value;

  const payrollDetails = hasPayroll ? (
    <>
      <dt>Current Payroll Deduction</dt>
      <dd>
        {type === "percentage"
          ? percentFormat(user.payroll.value)
          : moneyFormat(user.payroll.value)}{" "}
        per pay period
      </dd>
    </>
  ) : (
    ""
  );

  return (
    <article className="ContributionTotals">
      <dl style={{ width: "100%" }}>
        <dt>{new Date().getFullYear()} Contributions Total</dt>
        <dd>{moneyFormat(user.contributions.yearTotal)}</dd>
        <dt>Lifetime Contributions Total</dt>
        <dd>{moneyFormat(user.contributions.lifetime)}</dd>
        {payrollDetails}
      </dl>
    </article>
  );
}

export default ContributionTotals;
