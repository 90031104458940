import React, { createContext, useState } from "react";

export const PayrollContext = createContext();

const PayrollProvider = ({ children }) => {
  const [payroll, setPayroll] = useState(null);

  return (
    <PayrollContext.Provider value={{ payroll, setPayroll }}>
      {children}
    </PayrollContext.Provider>
  );
};

export default PayrollProvider;
