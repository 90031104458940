import styled from "styled-components";
import PayrollButton from "./PayrollButton";
import { getConfig } from "../utils/helpers";
import PayrollOtherButton from "./PayrollOtherButton";
import { getUserFromStorage } from "../api/user";

const CheckMark = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 2px solid ${() => getConfig().color || "hsl(218deg 75% 46%)"};
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "✓";
    color: ${() => getConfig().color || "hsl(218deg 75% 46%)"};
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

function PayrollDeductionSelector() {
  const instanceConfig = getConfig();
  const amounts = instanceConfig?.payroll?.amounts;
  const user = getUserFromStorage();
  const currentValue = parseFloat(user?.payroll?.value);

  return (
    <article className="contributionAmounts">
      <h2>Set Payroll Deduction</h2>
      <div className="contributionAmountsList cols-2">
        {amounts.map((amount, index) => (
          <ButtonWrapper key={index}>
            <PayrollButton amount={amount} />
            {currentValue === amount && <CheckMark />}
          </ButtonWrapper>
        ))}
        <PayrollOtherButton otherType="payroll" />
      </div>
    </article>
  );
}

export default PayrollDeductionSelector;
