const moneyFormat = (amount) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    amount
  );
const percentFormat = (amount) => `${amount}%`;

const getFreshConfig = () => {
  sessionStorage.removeItem("config");

  let params = new URLSearchParams(document.location.search);
  const getColor = params.get("color") || "#000";
  const getInstanceID = params.get("id") || 0;
  const getAction = parseInt(params.get("action") || 0);
  const getAuth = `Basic ${params.get("auth") || ""}`;
  const getAmounts = params.get("amounts")
    ? params.get("amounts").split(",")
    : [5.0, 10.0, 25.0, 50.0, 100.0, 250.0];
  const getApiUrl = params.get("url") || "";
  const getLogo = params.get("logo") || "";
  const getCap = params.get("cap") || false;
  const getCapMsg = params.get("capMsg") || "";
  const getEnablePhoneLookup = params.get("enablePhoneLookup") || false;
  const getEnableUserCreate = params.get("enableUserCreate") || false;
  const getPayroll =
    params.get("payroll")?.length || false
      ? JSON.parse(params.get("payroll") || "")
      : {};

  const config = {
    amounts: getAmounts,
    auth: getAuth,
    action: getAction,
    instanceID: getInstanceID,
    color: getColor,
    apiUrl: getApiUrl,
    logo: getLogo,
    cap: getCap,
    capMsg: getCapMsg,
    enablePhoneLookup: getEnablePhoneLookup,
    enableUserCreate: getEnableUserCreate,
    payroll: getPayroll,
  };

  return config;
};

const setConfig = async () => {
  const defaultConfig = getFreshConfig();
  sessionStorage.setItem("config", JSON.stringify(defaultConfig));
};

const getConfig = () => {
  const localConfig = sessionStorage.getItem("config");
  return localConfig ? JSON.parse(localConfig) : getFreshConfig();
};

const getAPIGetHeader = () => ({
  method: "GET",
  headers: {
    Authorization: getConfig().auth,
    "Content-type": "application/json; charset=UTF-8",
  },
});

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const stateOptions = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export {
  moneyFormat,
  percentFormat,
  getConfig,
  setConfig,
  getAPIGetHeader,
  debounce,
  stateOptions,
};
