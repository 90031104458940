import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PayrollContext } from "../utils/PayrollProvider";
import { getUserFromStorage } from "../api/user";
import { getConfig } from "../utils/helpers";
import {
  OtherBox,
  OtherInput,
  OtherInputContainer,
  StyledContributionButton,
} from "../utils/styled";

function PayrollOtherButton() {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const { setPayroll } = useContext(PayrollContext);
  const navigate = useNavigate();
  const user = getUserFromStorage();

  const type = getConfig()?.payroll?.type || "dollar";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!amount) return;

    setPayroll({
      id: parseInt(user.id || 0),
      amount: parseFloat(amount || 0),
    });
    navigate("/payroll");
  };

  return (
    <>
      {open ? (
        <OtherBox>
          <div className="OtherHeading">
            <h2>Other Amount</h2>
            <p>Please enter the amount you would like to contribute.</p>
          </div>
          <OtherInputContainer isPercent={type === "percentage"}>
            <OtherInput
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              required
              type="number"
              step="0.01"
            />
          </OtherInputContainer>
          <StyledContributionButton onClick={handleSubmit}>
            Next Step
          </StyledContributionButton>
        </OtherBox>
      ) : (
        <StyledContributionButton onClick={(e) => setOpen(true)}>
          Other
        </StyledContributionButton>
      )}
    </>
  );
}

export default PayrollOtherButton;
